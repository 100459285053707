import React, { Component } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import Main from '../Main'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n'
import { IntlProvider, addLocaleData } from 'react-intl'
import 'intl'
import en from 'react-intl/locale-data/en'
import 'intl/locale-data/jsonp/en'
import ka from 'react-intl/locale-data/ka'
import 'intl/locale-data/jsonp/ka'
import '../all.sass'

const getIdJsonUrl = (id, langKey, jsonData) => {
    if (id !== 'undefined') {
        let res
        switch (langKey) {
            // we get the name of the page according the id
            case 'en':
                res = jsonData[id].en
                break
            case 'ka':
                res = jsonData[id].ka
                break
            default:
                return ' '
        }
        return res
    } else {
        console.log('missed id in the getIdUrl() function!')
    }
}

const startPath = (langKey, langsMenu, baseName, _url) => {
    const lengthLangKey = langKey.includes('en') ? '' : langKey.length
    const indx = _url.indexOf(baseName)
    const basePath = _url.slice(lengthLangKey + 1, indx)
    return basePath
}

const checkPath = (langKey, _url, idArticle, jsonData) => {
    let baseName
    if (idArticle !== 'undefined') {
        baseName = getIdJsonUrl(idArticle, langKey, jsonData)
    }
    return [baseName, idArticle]
}

const setLangsMenu = (langsMenu, id, basePath, jsonData) => {
    if (id !== 'undefined') {
        langsMenu[0].link =
            `/${basePath}` + getIdJsonUrl(id, 'en', jsonData) + '/'
        langsMenu[1].link =
            `/ka/${basePath}` + getIdJsonUrl(id, 'ka', jsonData) + '/'
    } else {
        console.log('missed id in the setLangsMenu() function!')
    }
}

// add concatenated locale data
addLocaleData([...en, ...ka])

class TemplateWrapper extends Component {
    constructor(props) {
        super(props)
        this.children = this.props.children
        const data = this.props.data
        this.description = data.markdownRemark.frontmatter.description
        const jsonData = this.props.jsonData
        this.className = this.props.className
        const location = this.props.location
        this.title = data.markdownRemark.frontmatter.title
        const url = location.pathname
        const { langs, defaultLangKey } = data.site.siteMetadata.languages
        this.langKey = getCurrentLangKey(langs, defaultLangKey, url)
        this.homeLink = `/${this.langKey}/`
        this.langsMenu = getLangs(
            langs,
            this.langKey,
            getUrlForLang(this.homeLink, url)
        )
        const idArticle = data.markdownRemark.frontmatter.id
        const id = Number(idArticle) - 1
        const baseName = checkPath(this.langKey, url, id, jsonData)
        var basePath = startPath(this.langKey, this.langsMenu, baseName[0], url)
        // finally here we set the desired url...
        setLangsMenu(this.langsMenu, baseName[1], basePath, jsonData)

        // get the appropriate message file based on langKey
        // at the moment this assumes that langKey will provide us
        // with the appropriate language code
        this.i18nMessages = require(`../../data/messages/${this.langKey}`)
    }

    render() {
        return (
            <IntlProvider locale={this.langKey} messages={this.i18nMessages}>
                <div>
                    <Helmet key="app-head" defaultTitle={this.title}>
                        <html lang={this.langKey} />
                        <meta name="description" content={this.description} />
                    </Helmet>
                    <Header
                        langKey={this.langKey}
                        langs={this.langsMenu}
                        menu={this.menuTree}
                    />
                    <Main key="app-main" className={this.className}>
                        {this.children}
                    </Main>
                    <Footer langKey={this.langKey} />
                </div>
            </IntlProvider>
        )
    }
}

TemplateWrapper.propTypes = {
    children: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    jsonData: PropTypes.array.isRequired,
    className: PropTypes.string,
    location: PropTypes.object.isRequired,
}

export default TemplateWrapper
